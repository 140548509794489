const miniMenu = document.querySelector(`[data-custom-target='mini-menu']`)
const bigMenu = document.querySelector(`[data-custom-target='big-menu']`)
const bigMenuOpen = document.getElementById("big-menu")
const adminMenu = document.getElementById("admin-menu")
const bigMenuClose = document.querySelector(`[data-custom-target-close='big-menu']`)

// document.querySelectorAll(".password-shower")?.forEach(item => {
//   item?.addEventListener("click", () => {
//     item?.firstElementChild?.classList.toggle("fa-eye-slash")
//     item?.previousElementSibling?.setAttribute("type", `${item?.previousElementSibling?.getAttribute("type") === "text" ? 'password' : 'text'}`)
//   })
// })

document.getElementById("mini-menu").addEventListener("click", function () {
  if (miniMenu.classList.contains("active")) {
    miniMenu.style.height = 0;
    miniMenu.style.opacity = 0;
    setTimeout(() => {
      miniMenu.style.display = "none";
      miniMenu.classList.remove("active");
      document.getElementById("main-body").classList.remove("active")
      document.querySelector("html").classList.remove("overflow-hidden")
    }, 250);
  } else {
    document.getElementById("main-body").classList.add("active")
    document.querySelector("html").classList.add("overflow-hidden")
    miniMenu.classList.add("active");
    miniMenu.style.display = "block";
    miniMenu.style.height = miniMenu.scrollHeight + "px";
    miniMenu.style.opacity = 1;
  }
})

bigMenuOpen && bigMenuOpen.addEventListener("click", function () {
  this.classList.add("active")
  bigMenu.classList.add("active")
  document.getElementById("main-body").classList.add("active")
})

bigMenuClose && bigMenuClose.addEventListener("click", function () {
  bigMenu.classList.remove("active")
  bigMenuOpen.classList.remove("active")
  document.getElementById("main-body").classList.remove("active")
  document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
    if (item.classList.contains("active")) {
      setTimeout(() => {
        item.click()
      }, 400)
    }
  })
})

adminMenu && adminMenu.addEventListener("click", () => {
  document.querySelector(".admin-aside").classList.toggle("active");
  document.querySelector(".admin-aside-container").classList.toggle("active");
  if (!document.querySelector(".admin-aside_list__i.accord .accordion-button").classList.contains("collapsed")) {
    document.querySelector(".admin-aside_list__i.accord .accordion-button").click()
  }
})


const mql = window.matchMedia("(max-width: 992px)");


if (!mql.matches) {
  miniMenu.style.display = "flex"
  miniMenu.style.opacity = null
  miniMenu.style.height = null
  miniMenu.classList?.remove("active")
}

mql.onchange = (e) => {
  if (e.matches) {
    miniMenu.style.display = "none"
    miniMenu.style.opacity = 0
    miniMenu.style.height = 0;
    if (document.querySelector(".admin-aside") && document.querySelector(".admin-aside-container")) {
      document.querySelector(".admin-aside").classList.remove("active");
      document.querySelector(".admin-aside-container").classList.remove("active");
    }
  } else {
    miniMenu.style.display = "flex"
    miniMenu.style.opacity = null
    miniMenu.style.height = null
    miniMenu.classList?.remove("active")
  }
};

window.addEventListener("click", (event) => {
  if (!event.target.closest("[data-custom-target='mini-menu']") && !event.target.closest("#mini-menu") && miniMenu.classList.contains("active")) {
    miniMenu.style.height = 0;
    miniMenu.style.opacity = 0;
    setTimeout(() => {
      miniMenu.style.display = "none";
      miniMenu.classList.remove("active");
      document.getElementById("main-body").classList.remove("active")
      document.querySelector("html").classList.remove("overflow-hidden")
    }, 250);
  }
  
  if (!event.target.closest("[data-custom-target='big-menu']") && !event.target.closest("#big-menu") && bigMenu && bigMenu.classList.contains("active")) {
    bigMenu.classList.remove("active")
    bigMenuOpen.classList.remove("active")
    document.getElementById("main-body").classList.remove("active")
    
    document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
      if (item.classList.contains("active")) {
        setTimeout(() => {
          item.click()
        }, 400)
      }
    })
  }
})

setTimeout(() => {
  if (document.querySelector(".canvasjs-chart-credit")) {
    document.querySelector(".canvasjs-chart-credit")?.remove()
  }
}, 500)


window.addEventListener("resize", () => {
  if (mql.matches) {
    document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
      if (item.classList.contains("active")) {
        item?.parentElement?.classList?.add("active")
        array.forEach(itm => !itm.parentElement.classList.contains("active") && itm?.parentElement?.classList?.add("remove"))
      }
    })
  } else {
    document.querySelectorAll(".btn-menu-in").forEach(item => item?.parentElement?.classList?.remove("remove"))
  }
})

document.querySelectorAll(".btn-menu-in").forEach((item, number, array) => {
  item.addEventListener("click", function () {
    item.classList.toggle("active")
    const srcChange = item.firstElementChild.firstElementChild.src.split(`smartphone${item.classList.contains("active") ? "" : "-active"}`)
    item.firstElementChild.firstElementChild.src = srcChange[0] + `smartphone-active` + srcChange[1];
    
    if (mql.matches) {
      item.parentElement.classList.toggle("active")
    }
    
    array.forEach(itm => {
      
      if (item.hash.substr(1) !== itm.hash.substr(1) && !mql.matches) {
        itm.classList.remove("active")
        itm.setAttribute("disabled", "")
        setTimeout(() => {
          itm.removeAttribute("disabled")
        }, 400)
      } else {
        if (item.hash.substr(1) !== itm.hash.substr(1) && mql.matches) {
          if (!itm.parentElement.classList.contains("active")) {
            itm.parentElement.classList.toggle("remove")
          }
        }
        
        
      }
      const targetID = document.querySelector(`#${itm.hash.substr(1)}`)
      
      if (targetID && targetID.classList.contains("show")) {
        targetID.classList.remove("show")
        targetID.classList.remove("collapse")
        targetID.classList.add("collapsing")
        setTimeout(() => {
          targetID.classList.remove("collapsing")
          targetID.classList.add("collapse")
        }, 400)
      }
      
      if (!itm.classList.contains("active")) {
        const srcChange = itm.firstElementChild.firstElementChild.src.split("smartphone-active")
        if (srcChange.length === 2) {
          itm.firstElementChild.firstElementChild.src = srcChange[0] + "smartphone" + srcChange[1]
        }
      }
      
    })
  })
})

document.querySelectorAll(".product-box").forEach((item, number, array) => item.addEventListener("click", () => {
  array.forEach(itm => itm.classList.remove("active"))
  item.classList.add("active")
}))


function classChange(e, name = null, activeClass = null) {
  e.classList.toggle(activeClass ?? 'bg-d3B15e');
  
  if (name) {
    if (e.classList.contains(activeClass ?? 'bg-d3B15e')) {
      const srcChange = e.firstElementChild.src.split(`${name}`);
      e.firstElementChild.src = srcChange[0] + `${name}-active` + srcChange[1];
    } else {
      const srcChange = e.firstElementChild.src.split(`${name}-active`);
      e.firstElementChild.src = srcChange[0] + `${name}` + srcChange[1];
    }
  }
}

function dropChange(e, name) {
  for (let i = 0; i < e.parentElement?.parentElement?.children?.length; i++) {
    e?.parentElement?.parentElement?.children?.[i].firstElementChild.classList.remove("active", "pe-none")
  }
  e.classList.add("active", "pe-none");
  document.getElementById(name).innerHTML = e.textContent;
}


//  Script.js
const rangevalue =
  document.querySelector(".slider-container .price-slider");
const rangeInputvalue =
  document.querySelectorAll(".range-input input");

// Set the price gap
let priceGap = 500;

// Adding event listners to price input elements
const priceInputvalue =
  document.querySelectorAll(".price-field");
for (let i = 0; i < rangeInputvalue.length; i++) {
  let minValState =
    parseInt(rangeInputvalue[0].value);
  let maxValState =
    parseInt(rangeInputvalue[1].value);
  
  if ((maxValState - minValState) < priceGap) {
    if (i === 0) {
      rangeInputvalue[0].value = maxValState - priceGap;
      priceInputvalue[0].firstElementChild.textContent = maxValState - priceGap;
    } else {
      rangeInputvalue[1].value = minValState + priceGap;
      priceInputvalue[1].firstElementChild.textContent = minValState + priceGap;
    }
  } else {
    priceInputvalue[0].firstElementChild.textContent = minValState;
    priceInputvalue[1].firstElementChild.textContent = maxValState;
    rangevalue.style.left =
      `${(minValState / rangeInputvalue[0].max) * 100}%`;
    priceInputvalue[0].style.left =
      `${((minValState / rangeInputvalue[0].max) * 100) - (((maxValState / rangeInputvalue[1].max) * 100) < 50 ? 14.5 : 10)}%`;
    rangevalue.style.right =
      `${100 - (maxValState / rangeInputvalue[1].max) * 100}%`;
    priceInputvalue[1].style.right =
      `${100 - ((maxValState / rangeInputvalue[1].max) * 100) - (((maxValState / rangeInputvalue[1].max) * 100) < 50 ? 14.5 : 10)}%`;
  }
  
  rangeInputvalue[i].addEventListener("input", e => {
    let minVal =
      parseInt(rangeInputvalue[0].value);
    let maxVal =
      parseInt(rangeInputvalue[1].value);
    
    
    let diff = maxVal - minVal
    if (diff < priceGap) {
      if (e.target.className === "min-range") {
        rangeInputvalue[0].value = maxVal - priceGap;
        priceInputvalue[0].firstElementChild.textContent = maxVal - priceGap;
      } else {
        rangeInputvalue[1].value = minVal + priceGap;
        priceInputvalue[1].firstElementChild.textContent = minVal + priceGap;
      }
    } else {
      // Update price inputs and range progress
      priceInputvalue[0].firstElementChild.textContent = minVal;
      priceInputvalue[1].firstElementChild.textContent = maxVal;
      rangevalue.style.left =
        `${(minVal / rangeInputvalue[0].max) * 100}%`;
      priceInputvalue[0].style.left =
        `${((minVal / rangeInputvalue[0].max) * 100) - (((maxVal / rangeInputvalue[1].max) * 100) < 50 ? 14.5 : 10)}%`;
      rangevalue.style.right =
        `${100 - (maxVal / rangeInputvalue[1].max) * 100}%`;
      priceInputvalue[1].style.right =
        `${100 - ((maxVal / rangeInputvalue[1].max) * 100) - (((maxVal / rangeInputvalue[1].max) * 100) < 50 ? 14.5 : 10)}%`;
    }
  });
  
  rangeInputvalue[i].addEventListener("mouseover", () => {
    priceInputvalue[i].style.opacity = 1
  })
  
  rangeInputvalue[i].addEventListener("mouseout", () => {
    priceInputvalue[i].style.opacity = 0
  })
}


const form = document.getElementById('phone-check')
const inputs = form && form.querySelectorAll('input')
const KEYBOARDS = {
  backspace: 8,
  arrowLeft: 37,
  arrowRight: 39,
}

function handleInput(e) {
  const input = e.target
  const nextInput = input.nextElementSibling
  if (nextInput && input.value) {
    nextInput.focus()
    if (nextInput.value) {
      nextInput.select()
    }
  }
}

function handlePaste(e) {
  e.preventDefault()
  const paste = e.clipboardData.getData('text')
  inputs.forEach((input, i) => {
    input.value = paste[i] || ''
  })
}

function handleBackspace(e) {
  const input = e.target
  if (input.value) {
    input.value = ''
    return
  }
  
  input.previousElementSibling.focus()
}

function handleArrowLeft(e) {
  const previousInput = e.target.previousElementSibling
  if (!previousInput) return
  previousInput.focus()
}

function handleArrowRight(e) {
  const nextInput = e.target.nextElementSibling
  if (!nextInput) return
  nextInput.focus()
}

form && form.addEventListener('input', handleInput)
inputs && inputs[0].addEventListener('paste', handlePaste)

inputs && inputs.forEach(input => {
  input.addEventListener('focus', e => {
    setTimeout(() => {
      e.target.select()
    }, 0)
  })
  
  input.addEventListener('keydown', e => {
    switch (e.keyCode) {
      case KEYBOARDS.backspace:
        handleBackspace(e)
        break
      case KEYBOARDS.arrowLeft:
        handleArrowLeft(e)
        break
      case KEYBOARDS.arrowRight:
        handleArrowRight(e)
        break
      default:
    }
  })
})

function countdownCode(timeleft) {
  document.getElementById("phone-timer").textContent = "120"
  let downloadTimer = setInterval(function () {
    timeleft--;
    document.getElementById("phone-timer").textContent = timeleft;
    if (timeleft <= 0) {
      clearInterval(downloadTimer);
      document.getElementById("phone-timer").textContent = ""
    }
    
    if (document.getElementById("phone-check").style.display !== "block") {
      clearInterval(downloadTimer);
    }
  }, 1000);
}

document.querySelectorAll(".color-detail").length && document.querySelectorAll(".color-detail").forEach((item, num, arrayList) => {
  item.addEventListener("click", () => {
    arrayList.forEach(res => res.classList.remove("active"));
    item.classList.add("active")
  })
})

document.querySelectorAll("[data-attribute]").length && document.querySelectorAll("[data-attribute]").forEach((item, num, arrayList) => {
  item.addEventListener("click", () => {
    arrayList.forEach(res => res.dataset.attribute === item.dataset.attribute && res.classList.remove("active"));
    item.classList.add("active")
  })
})

document.querySelectorAll("[data-pointer-progress]").length && document.querySelectorAll("[data-pointer-progress]").forEach((item, num, arrayList) => {
  item.addEventListener("click", () => {
    arrayList.forEach(res => {
      res.querySelector(".rounded-circle").classList.remove("active")
    })
    for (let i = 1; i <= parseInt(item.dataset.pointerProgress); i++) {
      let element = document.querySelector(`[data-pointer-progress='${i}']`)
      element.querySelector(".rounded-circle").classList.add("active")
    }
    
    document.getElementById("progress-sale").style.width = item.dataset.progressTarget
  })
})





